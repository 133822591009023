var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card-actions', {
    attrs: {
      "title": "Filters",
      "action-collapse": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mb-1 custom-font",
    attrs: {
      "options": _vm.statusOption,
      "reduce": function reduce(item) {
        return item.value;
      },
      "label": "name",
      "placeholder": "Select Status"
    },
    on: {
      "input": function input($event) {
        return _vm.loadItems();
      }
    },
    model: {
      value: _vm.selectedStatusId,
      callback: function callback($$v) {
        _vm.selectedStatusId = $$v;
      },
      expression: "selectedStatusId"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mb-1 custom-font",
    attrs: {
      "options": _vm.packages,
      "reduce": function reduce(item) {
        return item.id;
      },
      "label": "title",
      "placeholder": "Select Packages"
    },
    on: {
      "input": function input($event) {
        return _vm.loadItems();
      }
    },
    model: {
      value: _vm.selectedPackageId,
      callback: function callback($$v) {
        _vm.selectedPackageId = $$v;
      },
      expression: "selectedPackageId"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "12"
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "placeholder": "Next billing date range",
      "config": {
        mode: 'range',
        defaultDate: [_vm.filterStartDate, _vm.filterEndDate]
      }
    },
    on: {
      "input": function input($event) {
        return _vm.loadItems();
      }
    },
    model: {
      value: _vm.filterRangeDate,
      callback: function callback($$v) {
        _vm.filterRangeDate = $$v;
      },
      expression: "filterRangeDate"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "12"
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "placeholder": "On Boarding date range",
      "config": {
        mode: 'range',
        defaultDate: [_vm.filterOnBoardStartDate, _vm.filterOnBoardEndDate]
      }
    },
    on: {
      "input": function input($event) {
        return _vm.loadItems();
      }
    },
    model: {
      value: _vm.filterOnBoardRangeDate,
      callback: function callback($$v) {
        _vm.filterOnBoardRangeDate = $$v;
      },
      expression: "filterOnBoardRangeDate"
    }
  })], 1)], 1)], 1), _c('b-card', [_c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('b-form-input', {
    staticClass: "d-inline-block mr-sm-1 mb-1 mb-sm-0",
    attrs: {
      "placeholder": "Search...",
      "type": "text"
    },
    on: {
      "keyup": _vm.onSearch
    },
    model: {
      value: _vm.searchTerm,
      callback: function callback($$v) {
        _vm.searchTerm = $$v;
      },
      expression: "searchTerm"
    }
  })], 1), _c('b-col', {
    staticClass: "d-flex justify-content-end align-items-center",
    attrs: {
      "lg": "8"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.showModal
    }
  }, [_vm._v(" Create Company ")])], 1)], 1), _c('vue-good-table', {
    attrs: {
      "mode": "remote",
      "total-rows": _vm.totalRecords,
      "is-loading": _vm.isLoading,
      "rows": _vm.rows,
      "sort-options": {
        enabled: false,
        multipleColumns: true,
        initialSortBy: [{
          field: 'id',
          type: 'desc'
        }, {
          field: 'name',
          type: 'desc'
        }, {
          field: 'created_at',
          type: 'desc'
        }]
      },
      "columns": _vm.columns,
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      },
      "style-class": "vgt-table striped"
    },
    on: {
      "update:isLoading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "update:is-loading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$row, _props$row2, _props$row3, _props$row4, _props$row5, _props$row6, _props$row7, _props$row8, _props$row9, _props$row10, _props$row11, _props$row12, _props$row13;
        return [props.column.field === 'format_name' ? _c('router-link', {
          staticClass: "font-weight-bold",
          attrs: {
            "to": {
              name: 'company-subscription-details',
              params: {
                payment_method: props.row.payment_method,
                id: props.row.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row = props.row) === null || _props$row === void 0 ? void 0 : _props$row.name) + " ")]) : _vm._e(), props.column.field === 'format_payment_method' ? _c('span', [_c('b-badge', {
          attrs: {
            "variant": _vm.getPaymentMethodVariant(props.row.payment_method_string)
          }
        }, [_vm._v(" " + _vm._s(_vm.getPaymentMethodText(props.row.payment_method_string)) + " ")])], 1) : _vm._e(), props.column.field === 'format_package_price' ? _c('span', [((_props$row2 = props.row) === null || _props$row2 === void 0 ? void 0 : _props$row2.payment_method) == 2 || ((_props$row3 = props.row) === null || _props$row3 === void 0 ? void 0 : _props$row3.payment_method) == 3 ? [_vm._v(" BDT " + _vm._s((_props$row4 = props.row) === null || _props$row4 === void 0 ? void 0 : _props$row4.package_price) + " ")] : ((_props$row5 = props.row) === null || _props$row5 === void 0 ? void 0 : _props$row5.payment_method) == 1 ? [_vm._v(" USD " + _vm._s((_props$row6 = props.row) === null || _props$row6 === void 0 ? void 0 : _props$row6.package_price) + " ")] : _vm._e()], 2) : _vm._e(), props.column.field === 'format_address' ? _c('span', [(_props$row7 = props.row) !== null && _props$row7 !== void 0 && _props$row7.address ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }, {
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-icon",
          attrs: {
            "variant": "outline-primary",
            "title": (_props$row8 = props.row) === null || _props$row8 === void 0 ? void 0 : _props$row8.address
          }
        }, [_c('feather-icon', {
          staticClass: "btn-icon",
          attrs: {
            "icon": "InfoIcon"
          }
        })], 1) : _vm._e()], 1) : _vm._e(), props.column.field === 'format_remarks' ? _c('span', [(_props$row9 = props.row) !== null && _props$row9 !== void 0 && _props$row9.remarks ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }, {
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-icon",
          attrs: {
            "variant": "outline-primary",
            "title": (_props$row10 = props.row) === null || _props$row10 === void 0 ? void 0 : _props$row10.remarks
          }
        }, [_c('feather-icon', {
          staticClass: "btn-icon",
          attrs: {
            "icon": "InfoIcon"
          }
        })], 1) : _vm._e()], 1) : _vm._e(), props.column.field === 'format_status' ? _c('span', [_c('b-badge', {
          attrs: {
            "variant": _vm.getStatusVariant(props.row.status_string)
          }
        }, [_vm._v(" " + _vm._s(_vm.getStatusText(props.row.status_string)) + " ")])], 1) : _vm._e(), props.column.field === 'action' ? _c('span', [_c('span', {
          on: {
            "click": function click($event) {
              return _vm.onShow(props.row);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "Edit2Icon",
            "title": "Edit",
            "size": "16"
          }
        })], 1), ((_props$row11 = props.row) === null || _props$row11 === void 0 ? void 0 : _props$row11.payment_method) === 1 ? [_c('span', {
          on: {
            "click": function click($event) {
              return _vm.stripePauseSubscription(props.row);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "PauseCircleIcon",
            "title": "Pause",
            "size": "18"
          }
        })], 1), _c('span', {
          on: {
            "click": function click($event) {
              return _vm.stripeResumeSubscription(props.row);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "PlayCircleIcon",
            "title": "Resume",
            "size": "18"
          }
        })], 1), _c('span', {
          on: {
            "click": function click($event) {
              return _vm.stripeCancelSubscription(props.row);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "XCircleIcon",
            "title": "Cancel",
            "size": "18"
          }
        })], 1)] : ((_props$row12 = props.row) === null || _props$row12 === void 0 ? void 0 : _props$row12.payment_method) === 2 ? [_c('span', {
          on: {
            "click": function click($event) {
              return _vm.sslCommerzPauseSubscription(props.row);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "PauseCircleIcon",
            "title": "Pause",
            "size": "18"
          }
        })], 1), _c('span', {
          on: {
            "click": function click($event) {
              return _vm.sslCommerzResumeSubscription(props.row);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "PlayCircleIcon",
            "title": "Resume",
            "size": "18"
          }
        })], 1), _c('span', {
          on: {
            "click": function click($event) {
              return _vm.sslCommerzCancelSubscription(props.row);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "XCircleIcon",
            "title": "Cancel",
            "size": "18"
          }
        })], 1)] : ((_props$row13 = props.row) === null || _props$row13 === void 0 ? void 0 : _props$row13.payment_method) === 3 ? [_c('span', {
          on: {
            "click": function click($event) {
              return _vm.showRecurringModal(props.row);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "RefreshCcwIcon",
            "title": "Recurring Payment",
            "size": "18"
          }
        })], 1), _c('span', {
          on: {
            "click": function click($event) {
              return _vm.manualPauseSubscription(props.row);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "PauseCircleIcon",
            "title": "Pause",
            "size": "18"
          }
        })], 1), _c('span', {
          on: {
            "click": function click($event) {
              return _vm.manualResumeSubscription(props.row);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "PlayCircleIcon",
            "title": "Resume",
            "size": "18"
          }
        })], 1), _c('span', {
          on: {
            "click": function click($event) {
              return _vm.manualCancelSubscription(props.row);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "XCircleIcon",
            "title": "Cancel",
            "size": "18"
          }
        })], 1)] : _vm._e()], 2) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '25', '50', '100', '500']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  }), _c('b-modal', {
    attrs: {
      "id": "modal-company-form",
      "centered": "",
      "title": _vm.modelType === 'editModel' ? 'Edit Company' : 'Create Company',
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenModal
    }
  }, [_c('validation-observer', {
    ref: "companyValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.validationForm($event);
      }
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Name",
      "label-for": "name"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "name",
      "vid": "name",
      "rules": "required|max:255"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "name",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Company"
          },
          model: {
            value: _vm.name,
            callback: function callback($$v) {
              _vm.name = $$v;
            },
            expression: "name"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Email ",
      "label-for": "email"
    }
  }, [_c('validation-provider', {
    attrs: {
      "type": "email",
      "name": "email",
      "rules": "".concat(_vm.modelType === 'editModel' ? '' : 'required', "|email"),
      "vid": "email"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "email",
            "state": errors.length > 0 ? false : null,
            "name": "email",
            "placeholder": "john@example.com"
          },
          model: {
            value: _vm.email,
            callback: function callback($$v) {
              _vm.email = $$v;
            },
            expression: "email"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Status",
      "label-for": "status"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "status",
      "vid": "status",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('v-select', {
          staticClass: "mb-1 custom-font",
          attrs: {
            "options": _vm.statusOption,
            "reduce": function reduce(item) {
              return item.value;
            },
            "label": "name",
            "placeholder": "Select Status"
          },
          model: {
            value: _vm.status,
            callback: function callback($$v) {
              _vm.status = $$v;
            },
            expression: "status"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Remarks",
      "label-for": "remarks"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "remarks",
      "vid": "remarks"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "remarks",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Remarks"
          },
          model: {
            value: _vm.remarks,
            callback: function callback($$v) {
              _vm.remarks = $$v;
            },
            expression: "remarks"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Address",
      "label-for": "address"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "address",
      "vid": "address"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('b-form-textarea', {
          attrs: {
            "id": "required-remarks",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Address",
            "rows": "2"
          },
          model: {
            value: _vm.address,
            callback: function callback($$v) {
              _vm.address = $$v;
            },
            expression: "address"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _vm.isCompanyFormSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-recurring-payment",
      "centered": "",
      "title": "Create Payment History",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenModal
    }
  }, [_c('validation-observer', {
    ref: "companyPaymentHistory"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.validCompanyPaymentHistory($event);
      }
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Transection ID",
      "label-for": "transaction_id"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "transaction_id",
      "vid": "transaction_id",
      "rules": "required|max:255"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "transaction_id",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Transaction id"
          },
          model: {
            value: _vm.transaction_id,
            callback: function callback($$v) {
              _vm.transaction_id = $$v;
            },
            expression: "transaction_id"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Package Name",
      "label-for": "package_name"
    }
  }, [_c('validation-provider', {
    attrs: {
      "type": "text",
      "name": "package_name",
      "vid": "package_name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "package_name",
            "state": errors.length > 0 ? false : null,
            "name": "text",
            "value": _vm.package_name,
            "disabled": ""
          },
          model: {
            value: _vm.package_name,
            callback: function callback($$v) {
              _vm.package_name = $$v;
            },
            expression: "package_name"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Package Amount",
      "label-for": "package_amount"
    }
  }, [_c('validation-provider', {
    attrs: {
      "type": "text",
      "name": "package_amount",
      "vid": "package_amount"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "package_amount",
            "state": errors.length > 0 ? false : null,
            "name": "text",
            "value": _vm.package_amount,
            "disabled": ""
          },
          model: {
            value: _vm.package_amount,
            callback: function callback($$v) {
              _vm.package_amount = $$v;
            },
            expression: "package_amount"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Next Payment Date",
      "label-for": "next_payment_date"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "next_payment_date",
      "vid": "next_payment_date",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var errors = _ref9.errors;
        return [_c('b-form-datepicker', {
          staticClass: "custom-font",
          attrs: {
            "id": "next_payment_date",
            "state": errors.length > 0 ? false : null,
            "locale": "en-US",
            "today-button": "",
            "close-button": "",
            "reset-button": ""
          },
          on: {
            "context": _vm.onContextPurchaseDate
          },
          model: {
            value: _vm.next_payment_date,
            callback: function callback($$v) {
              _vm.next_payment_date = $$v;
            },
            expression: "next_payment_date"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Attachment",
      "label-for": "file"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "attachment",
      "rules": "size:2048",
      "vid": "leave_request_file"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var errors = _ref10.errors;
        return [_c('b-form-file', {
          attrs: {
            "id": "file",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Attach a File Or Drop it Here",
            "drop-placeholder": "Drop file here..."
          },
          model: {
            value: _vm.file,
            callback: function callback($$v) {
              _vm.file = $$v;
            },
            expression: "file"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _vm.isCompanyPaymentHistoryFormSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }