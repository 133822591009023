<template>
  <div>
    <b-card-actions title="Filters" action-collapse>
      <!-- Filter -->
      <b-row>
        <b-col md="3" lg="3" xs="12">
          <v-select
            v-model="selectedStatusId"
            :options="statusOption"
            :reduce="(item) => item.value"
            label="name"
            placeholder="Select Status"
            class="mb-1 custom-font"
            @input="loadItems()"
          >
          </v-select>
        </b-col>

        <b-col md="3" lg="3" xs="12">
          <v-select
            v-model="selectedPackageId"
            :options="packages"
            :reduce="(item) => item.id"
            label="title"
            placeholder="Select Packages"
            class="mb-1 custom-font"
            @input="loadItems()"
          >
          </v-select>
        </b-col>
        <b-col md="3" lg="3" xs="12">
          <flat-pickr
            v-model="filterRangeDate"
            class="form-control"
            placeholder="Next billing date range"
            :config="{
              mode: 'range',
              defaultDate: [filterStartDate, filterEndDate],
            }"
            @input="loadItems()"
          />
        </b-col>

        <b-col md="3" lg="3" xs="12">
          <flat-pickr
            v-model="filterOnBoardRangeDate"
            class="form-control"
            placeholder="On Boarding date range"
            :config="{
              mode: 'range',
              defaultDate: [filterOnBoardStartDate, filterOnBoardEndDate],
            }"
            @input="loadItems()"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <b-card>
      <!-- search input -->
      <b-row class="mb-1">
        <b-col lg="4">
          <b-form-input
            v-model.lazy="searchTerm"
            placeholder="Search..."
            type="text"
            class="d-inline-block mr-sm-1 mb-1 mb-sm-0"
            @keyup="onSearch"
          />
        </b-col>
        <b-col lg="8" class="d-flex justify-content-end align-items-center">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="flex-shrink-0"
            variant="primary"
            @click="showModal"
          >
            Create Company
          </b-button>
        </b-col>
      </b-row>

      <!-- table -->
      <vue-good-table
        mode="remote"
        :total-rows="totalRecords"
        :is-loading.sync="isLoading"
        :rows="rows"
        :sort-options="{
          enabled: false,
          multipleColumns: true,
          initialSortBy: [
            { field: 'id', type: 'desc' },
            { field: 'name', type: 'desc' },
            { field: 'created_at', type: 'desc' },
          ],
        }"
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        style-class="vgt-table striped"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
      >
        <template slot="table-row" slot-scope="props">
          <!-- head -->
          <router-link
            :to="{
              name: 'company-subscription-details',
              params: { payment_method: props.row.payment_method, id: props.row.id }
            }"
            class="font-weight-bold"
            v-if="props.column.field === 'format_name'"
          >
            {{ props?.row?.name }}
          </router-link>

          <span v-if="props.column.field === 'format_payment_method'">
            <b-badge
              :variant="
                getPaymentMethodVariant(props.row.payment_method_string)
              "
            >
              {{ getPaymentMethodText(props.row.payment_method_string) }}
            </b-badge>
          </span>

          <span v-if="props.column.field === 'format_package_price'">
            <template
              v-if="
                props.row?.payment_method == 2 || props.row?.payment_method == 3
              "
            >
              BDT {{ props.row?.package_price }}
            </template>
            <template v-else-if="props.row?.payment_method == 1">
              USD {{ props.row?.package_price }}
            </template>
          </span>

          <span v-if="props.column.field === 'format_address'">
            <b-button
              v-if="props.row?.address"
              v-b-tooltip.hover
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
              :title="props.row?.address"
            >
              <feather-icon class="btn-icon" icon="InfoIcon" />
            </b-button>
          </span>

          <span v-if="props.column.field === 'format_remarks'">
            <b-button
              v-if="props.row?.remarks"
              v-b-tooltip.hover
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
              :title="props.row?.remarks"
            >
              <feather-icon class="btn-icon" icon="InfoIcon" />
            </b-button>
          </span>

          <span v-if="props.column.field === 'format_status'">
            <b-badge :variant="getStatusVariant(props.row.status_string)">
              {{ getStatusText(props.row.status_string) }}
            </b-badge>
          </span>

          <span v-if="props.column.field === 'action'">
            <span @click="onShow(props.row)">
              <feather-icon
                v-b-tooltip.hover
                icon="Edit2Icon"
                class="mr-50 custom-icon cursor-pointer"
                title="Edit"
                size="16"
              />
            </span>
            <template v-if="props.row?.payment_method === 1">
              <span @click="stripePauseSubscription(props.row)">
                <feather-icon
                  v-b-tooltip.hover
                  icon="PauseCircleIcon"
                  class="mr-50 custom-icon cursor-pointer"
                  title="Pause"
                  size="18"
                />
              </span>
              <span @click="stripeResumeSubscription(props.row)">
                <feather-icon
                  v-b-tooltip.hover
                  icon="PlayCircleIcon"
                  class="mr-50 custom-icon cursor-pointer"
                  title="Resume"
                  size="18"
                />
              </span>
              <span @click="stripeCancelSubscription(props.row)">
                <feather-icon
                  v-b-tooltip.hover
                  icon="XCircleIcon"
                  class="mr-50 custom-icon cursor-pointer"
                  title="Cancel"
                  size="18"
                />
              </span>
            </template>
            <template v-else-if="props.row?.payment_method === 2">
              <span @click="sslCommerzPauseSubscription(props.row)">
                <feather-icon
                  v-b-tooltip.hover
                  icon="PauseCircleIcon"
                  class="mr-50 custom-icon cursor-pointer"
                  title="Pause"
                  size="18"
                />
              </span>
              <span @click="sslCommerzResumeSubscription(props.row)">
                <feather-icon
                  v-b-tooltip.hover
                  icon="PlayCircleIcon"
                  class="mr-50 custom-icon cursor-pointer"
                  title="Resume"
                  size="18"
                />
              </span>
              <span @click="sslCommerzCancelSubscription(props.row)">
                <feather-icon
                  v-b-tooltip.hover
                  icon="XCircleIcon"
                  class="mr-50 custom-icon cursor-pointer"
                  title="Cancel"
                  size="18"
                />
              </span>
            </template>
            <template v-else-if="props.row?.payment_method === 3">
              <span @click="showRecurringModal(props.row)">
                <feather-icon
                  v-b-tooltip.hover
                  icon="RefreshCcwIcon"
                  class="mr-50 custom-icon cursor-pointer"
                  title="Recurring Payment"
                  size="18"
                />
              </span>
              <span @click="manualPauseSubscription(props.row)">
                <feather-icon
                  v-b-tooltip.hover
                  icon="PauseCircleIcon"
                  class="mr-50 custom-icon cursor-pointer"
                  title="Pause"
                  size="18"
                />
              </span>
              <span @click="manualResumeSubscription(props.row)">
                <feather-icon
                  v-b-tooltip.hover
                  icon="PlayCircleIcon"
                  class="mr-50 custom-icon cursor-pointer"
                  title="Resume"
                  size="18"
                />
              </span>
              <span @click="manualCancelSubscription(props.row)">
                <feather-icon
                  v-b-tooltip.hover
                  icon="XCircleIcon"
                  class="mr-50 custom-icon cursor-pointer"
                  title="Cancel"
                  size="18"
                />
              </span>
            </template>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '25', '50', '100', '500']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>

      <b-modal
        id="modal-company-form"
        centered
        :title="modelType === 'editModel' ? 'Edit Company' : 'Create Company'"
        hide-footer
        no-close-on-backdrop
        @hidden="hiddenModal"
      >
        <validation-observer ref="companyValidation">
          <b-form @submit.prevent="validationForm">
            <!-- name -->
            <b-form-group label="Name" label-for="name" class="required-label">
              <validation-provider
                #default="{ errors }"
                name="name"
                vid="name"
                rules="required|max:255"
              >
                <b-form-input
                  id="name"
                  v-model="name"
                  type="text"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Company"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- email -->
            <b-form-group
              label="Email "
              label-for="email"
              class="required-label"
            >
              <validation-provider
                #default="{ errors }"
                type="email"
                name="email"
                :rules="`${modelType === 'editModel' ? '' : 'required'}|email`"
                vid="email"
              >
                <b-form-input
                  id="email"
                  v-model="email"
                  :state="errors.length > 0 ? false : null"
                  name="email"
                  placeholder="john@example.com"
                  class="custom-font"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label="Status"
              label-for="status"
              class="required-label"
            >
              <validation-provider
                #default="{ errors }"
                name="status"
                vid="status"
                rules="required"
              >
                <v-select
                  v-model="status"
                  :options="statusOption"
                  :reduce="(item) => item.value"
                  label="name"
                  placeholder="Select Status"
                  class="mb-1 custom-font"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Remarks" label-for="remarks">
              <validation-provider
                #default="{ errors }"
                name="remarks"
                vid="remarks"
              >
                <b-form-input
                  id="remarks"
                  v-model="remarks"
                  type="text"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Remarks"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Address" label-for="address">
              <validation-provider
                #default="{ errors }"
                name="address"
                vid="address"
              >
                <b-form-textarea
                  id="required-remarks"
                  v-model="address"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Address"
                  rows="2"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- loading button -->
            <template v-if="isCompanyFormSubmitLoading">
              <b-button class="float-right" variant="primary" disabled>
                <b-spinner small />
                Loading...
              </b-button>
            </template>

            <!-- submit button -->
            <template v-else>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                class="float-right"
                variant="primary"
              >
                Submit
              </b-button>
            </template>
          </b-form>
        </validation-observer>
      </b-modal>
      <b-modal
        id="modal-recurring-payment"
        centered
        title="Create Payment History"
        hide-footer
        no-close-on-backdrop
        @hidden="hiddenModal"
      >
        <validation-observer ref="companyPaymentHistory">
          <b-form @submit.prevent="validCompanyPaymentHistory">
            <!-- name -->
            <b-form-group
              label="Transection ID"
              label-for="transaction_id"
              class="required-label"
            >
              <validation-provider
                #default="{ errors }"
                name="transaction_id"
                vid="transaction_id"
                rules="required|max:255"
              >
                <b-form-input
                  id="transaction_id"
                  v-model="transaction_id"
                  type="text"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Transaction id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- email -->
            <b-form-group
              label="Package Name"
              label-for="package_name"
              class="required-label"
            >
              <validation-provider
                #default="{ errors }"
                type="text"
                name="package_name"
                vid="package_name"
              >
                <b-form-input
                  id="package_name"
                  v-model="package_name"
                  :state="errors.length > 0 ? false : null"
                  name="text"
                  class="custom-font"
                  :value="package_name"
                  disabled
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label="Package Amount"
              label-for="package_amount"
              class="required-label"
            >
              <validation-provider
                #default="{ errors }"
                type="text"
                name="package_amount"
                vid="package_amount"
              >
                <b-form-input
                  id="package_amount"
                  v-model="package_amount"
                  :state="errors.length > 0 ? false : null"
                  name="text"
                  class="custom-font"
                  :value="package_amount"
                  disabled
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label="Next Payment Date"
              label-for="next_payment_date"
              class="required-label"
            >
              <ValidationProvider
                v-slot="{ errors }"
                name="next_payment_date"
                vid="next_payment_date"
                rules="required"
              >
                <b-form-datepicker
                  id="next_payment_date"
                  v-model="next_payment_date"
                  :state="errors.length > 0 ? false : null"
                  locale="en-US"
                  today-button
                  close-button
                  reset-button
                  class="custom-font"
                  @context="onContextPurchaseDate"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>

            <b-form-group
              label="Attachment"
              label-for="file"
            >
              <validation-provider
                #default="{ errors }"
                name="attachment"
                rules="size:2048"
                vid="leave_request_file"
              >
                <b-form-file
                  id="file"
                  v-model="file"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Attach a File Or Drop it Here"
                  drop-placeholder="Drop file here..."
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>



            <!-- loading button -->
            <template v-if="isCompanyPaymentHistoryFormSubmitLoading">
              <b-button class="float-right" variant="primary" disabled>
                <b-spinner small />
                Loading...
              </b-button>
            </template>

            <!-- submit button -->
            <template v-else>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                class="float-right"
                variant="primary"
              >
                Submit
              </b-button>
            </template>
          </b-form>
        </validation-observer>

      </b-modal>
    </b-card>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BFormDatepicker,
  BLink,
  VBTooltip,
  BRow,
  BFormFile,
  BCol,
  BFormTextarea,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { formatDateRange, minutesToHours } from "@/helpers/helpers";
import { required, max } from "@validations";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import UserCard from "@/layouts/components/UserCard.vue";
import store from "@/store";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";

export default {
  name: "CompanyView",
  components: {
    BFormTextarea,
    BCol,
    BRow,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BLink,
    BFormFile,
    UserCard,
    BCardActions,
    BFormDatepicker,
    flatPickr,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      modelType: "",
      companyId: "",
      name: "",
      email: "",
      contact_no: "",
      address: "",
      website: "",
      remarks: "",
      status: 1,
      // filter
      selectedStatusId: "",
      selectedPackageId: "",
      packages: [],
      filterStartDate: "",
      filterEndDate: "",
      filterRangeDate: "",

      filterOnBoardStartDate: "",
      filterOnBoardEndDate: "",
      filterOnBoardRangeDate: null,
      statusOption: [
        { name: "Complete", value: 1 },
        { name: "On Payment", value: 2 },
        { name: "Paused", value: 3 },
        { name: "Resume", value: 4 },
        { name: "Cancel", value: 5 },
        { name: "Trial", value: 6 },
      ],
      pageLength: 10,
      columns: [
        {
          label: "Company Name",
          field: "format_name",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Current Package",
          field: "package_title",
        },
        {
          label: "Payment Method",
          field: "format_payment_method",
        },
        {
          label: "Next Billing Date",
          field: "subscription_expire_date",
        },
        {
          label: "Next Billing Amount",
          field: "format_package_price",
        },
        {
          label: "Address",
          field: "format_address",
        },
        {
          label: "Remarks",
          field: "format_remarks",
        },
        {
          label: "Status",
          field: "format_status",
        },
        {
          label: "On Boarding",
          field: "created_at",
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
      isCompanyFormSubmitLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          { field: "id", type: "desc" },
          { field: "name", type: "desc" },
        ],
        page: 1,
        perPage: 10,
      },
      transaction_id: "",
      package_name: "",
      next_payment_date:"",
      package_amount:"",
      company_id: "",
      package_id: "",
      file: null,
      isCompanyPaymentHistoryFormSubmitLoading: false,
    };
  },

  methods: {
    showModal() {
      this.$bvModal.show("modal-company-form");
    },
    hiddenModal() {
      this.$bvModal.hide("modal-company-form");
      this.resetModal();
    },
    resetModal() {
      this.modelType = "";
      this.name = "";
      this.email = "";
      this.contact_no = "";
      this.address = "";
      this.website = "";
      this.status = 1;
      this.remarks = "";
    },
    showRecurringModal(data) {
      this.package_name = data.package_title;
      this.package_amount = data.package_price;
      this.company_id = data.id;
      this.package_id = data.package_id;
      this.$bvModal.show("modal-recurring-payment");
    },
    hiddenRecurringModal() {
      this.$bvModal.hide("modal-recurring-payment");
      this.resetRecurringModal();
    },
    resetRecurringModal() {
      this.transaction_id = "";
      this.package_name = "";
      this.next_payment_dat = "";
      this.package_amoun = "";
      this.company_id = "",
      this.package_id = "";
      this.file = null;
    },
    onShowPage(id) {
      this.$router.push({
        name: "admin-company-details",
        params: { id },
      });
    },
    async onShow(value) {
      this.modelType = "editModel";
      this.name = value.name;
      this.email = value.email;
      this.address = value.address;
      this.status = value.status;
      this.companyId = value?.id ? +value?.id : "";
      this.remarks = value.remarks;

      this.showModal();
    },
    async onDelete(row) {
      const { id, name } = row;
      this.$swal({
        title: "Warning!",
        text: `Are You Sure You Want To Delete ${name}?`,
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Delete",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(`/api/super-admin/companies/${id}`);

            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Company Deleted Successfully",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },

    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },

    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps };
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    onContextPurchaseDate(ctx) {
      this.purchaseDate = ctx.selectedYMD
    },

    async getCompanies(params) {
      return await this.$api.get("api/super-admin/companies", {
        params: {
          show: params.show,
          page: params.page,
          sort: params.sort,
          q: params.q,
          status: params.status,
          package: params.package,
          next_billing_start_date: params.startDate,
          next_billing_end_date: params.endDate,
          on_board_start_date: params.onBoardStartDate,
          on_board_end_date: params.onBoardEndDate,
        },
      });
    },

    async loadItems() {
      const { startDate, endDate } = formatDateRange(this.filterRangeDate);
      const onBoardStartDate = formatDateRange(this.filterOnBoardRangeDate);
      try {
        const [companies] = await Promise.all([
          this.getCompanies({
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.searchTerm,
            status: this.selectedStatusId,
            package: this.selectedPackageId,
            startDate: startDate,
            endDate: endDate,
            onBoardStartDate: onBoardStartDate.startDate,
            onBoardEndDate: onBoardStartDate.endDate,
          }),
        ]);

        const data = companies?.data?.data;

        const meta = companies?.data?.meta;

        this.packages = companies?.data?.meta?.all_packages?.map(
          (price_package) => ({
            id: price_package.id,
            title: `${
              price_package.title
            } ${price_package.billing_cycle.replace(/_/g, " ")}`,
          })
        );

        this.totalRecords = meta?.pagination?.total;
        this.rows = data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    async validationForm() {
      this.$refs.companyValidation.validate().then(async (success) => {
        if (success) {
          try {
            if (this.modelType === "editModel") {
              this.isCompanyFormSubmitLoading = true;
              await this.$api.put(
                `/api/super-admin/companies/${this.companyId}`,
                {
                  name: this.name,
                  email: this.email,
                  address: this.address,
                  status: this.status,
                  remarks: this.remarks,
                }
              );
              this.isCompanyFormSubmitLoading = false;
              this.loadItems();
              this.hiddenModal();
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Successfully Updated",
                },
              });
            } else {
              this.isCompanyFormSubmitLoading = true;
              await this.$api.post(
                "/api/super-admin/companies",

                {
                  name: this.name,
                  email: this.email,
                  address: this.address,
                  status: this.status,
                  remarks: this.remarks,
                }
              );
              this.isCompanyFormSubmitLoading = false;
              this.hiddenModal();

              this.loadItems();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Successfully Created",
                },
              });
            }
          } catch (error) {
            this.isCompanyFormSubmitLoading = false;

            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.companyValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
    async validCompanyPaymentHistory() {
      this.$refs.companyPaymentHistory.validate().then(async (success) => {
        if (success) {
          try { 
            
              this.isCompanyPaymentHistoryFormSubmitLoading = true;
              let formData = new FormData();
              formData.append("transaction_id", this.transaction_id);
              formData.append("package_amount", this.package_amount);
              formData.append("next_payment_date", this.next_payment_date);
              formData.append("company_id", this.company_id);
              formData.append("package_id", this.package_id);

              // Append file if exists
              if (this.file) {
                formData.append("file", this.file);
              }
              
              await this.$api.post(
                "/api/super-admin/manual-recurring-payment",
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              );
              this.isCompanyPaymentHistoryFormSubmitLoading = false;
              this.hiddenRecurringModal();

              this.loadItems();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Successfully Created",
                },
              });
          } catch (error) {
            this.isCompanyPaymentHistoryFormSubmitLoading = false;

            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.companyValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
    async getStripePauseSubscription(params) {
      return await this.$api.get("api/super-admin/pause-subscription", {
        params: {
          company_id: params.company_id,
        },
      });
    },
    async stripePauseSubscription(row) {
      this.$swal({
        title: "Warning!",
        text: `Are You Sure You Want To pause the subscription?`,
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await this.getStripePauseSubscription({
              company_id: row.id,
            });
            this.loadItems();
            if (response.data.status == "success") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: response.data.message,
                },
              });
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: response.data.message,
                },
              });
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },
    async getStripeResumeSubscription(params) {
      return await this.$api.get("api/super-admin/resume-subscription", {
        params: {
          company_id: params.company_id,
        },
      });
    },
    async stripeResumeSubscription(row) {
      this.$swal({
        title: "Warning!",
        text: `Are You Sure You Want To resume the subscription?`,
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await this.getStripeResumeSubscription({
              company_id: row.id,
            });
            this.loadItems();
            if (response.data.status == "success") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: response.data.message,
                },
              });
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: response.data.message,
                },
              });
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },
    async getStripeCancelSubscription(params) {
      return await this.$api.get("api/super-admin/cancel-subscription", {
        params: {
          company_id: params.company_id,
        },
      });
    },
    async stripeCancelSubscription(row) {
      this.$swal({
        title: "Warning!",
        text: `Are You Sure You Want To cancel the subscription?`,
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await this.getStripeCancelSubscription({
              company_id: row.id,
            });
            this.loadItems();
            if (response.data.status == "success") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: response.data.message,
                },
              });
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: response.data.message,
                },
              });
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },
    async postSslCommerzPauseSubscription(params) {
      return await this.$api.post(
        "api/super-admin/sslcommerz/pause-resume-cancel-subscription",
        {
          company_id: params.company_id,
          subscription_status: params.subscription_status,
        }
      );
    },
    async sslCommerzPauseSubscription(row) {
      this.$swal({
        title: "Warning!",
        text: `Are You Sure You Want To pause the subscription?`,
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await this.postSslCommerzPauseSubscription({
              company_id: row.id,
              subscription_status: 3,
            });
            this.loadItems();

            if (response.data.status == "success") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: response.data.message,
                },
              });
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: response.data.message,
                },
              });
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },
    async postSslCommerzResumeSubscription(params) {
      return await this.$api.post(
        "api/super-admin/sslcommerz/pause-resume-cancel-subscription",
        {
          company_id: params.company_id,
          subscription_status: params.subscription_status,
        }
      );
    },
    async sslCommerzResumeSubscription(row) {
      this.$swal({
        title: "Warning!",
        text: `Are You Sure You Want To resume the subscription?`,
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await this.postSslCommerzResumeSubscription({
              company_id: row.id,
              subscription_status: 4,
            });
            this.loadItems();

            if (response.data.status == "success") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: response.data.message,
                },
              });
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: response.data.message,
                },
              });
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },
    async postSslCommerzCancelSubscription(params) {
      return await this.$api.post(
        "api/super-admin/sslcommerz/pause-resume-cancel-subscription",
        {
          company_id: params.company_id,
          subscription_status: params.subscription_status,
        }
      );
    },
    async sslCommerzCancelSubscription(row) {
      this.$swal({
        title: "Warning!",
        text: `Are You Sure You Want To cancel the subscription?`,
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await this.postSslCommerzCancelSubscription({
              company_id: row.id,
              subscription_status: 5,
            });
            this.loadItems();
            if (response.data.status == "success") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: response.data.message,
                },
              });
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: response.data.message,
                },
              });
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },
    async postManualPauseSubscription(params) {
      return await this.$api.post(
        "api/super-admin/manual-payment/pause-resume-cancel-subscription",
        {
          company_id: params.company_id,
          subscription_status: params.subscription_status,
        }
      );
    },
    async manualPauseSubscription(row) {
      this.$swal({
        title: "Warning!",
        text: `Are You Sure You Want To pause the subscription?`,
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await this.postManualPauseSubscription({
              company_id: row.id,
              subscription_status: 3,
            });
            this.loadItems();

            if (response.data.status == "success") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: response.data.message,
                },
              });
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: response.data.message,
                },
              });
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },
    async postManualResumeSubscription(params) {
      return await this.$api.post(
        "api/super-admin/manual-payment/pause-resume-cancel-subscription",
        {
          company_id: params.company_id,
          subscription_status: params.subscription_status,
        }
      );
    },
    async manualResumeSubscription(row) {
      this.$swal({
        title: "Warning!",
        text: `Are You Sure You Want To resume the subscription?`,
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await this.postManualResumeSubscription({
              company_id: row.id,
              subscription_status: 4,
            });
            this.loadItems();

            if (response.data.status == "success") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: response.data.message,
                },
              });
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: response.data.message,
                },
              });
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },
    async postManualCancelSubscription(params) {
      return await this.$api.post(
        "api/super-admin/manual-payment/pause-resume-cancel-subscription",
        {
          company_id: params.company_id,
          subscription_status: params.subscription_status,
        }
      );
    },
    async manualCancelSubscription(row) {
      this.$swal({
        title: "Warning!",
        text: `Are You Sure You Want To cancel the subscription?`,
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await this.postManualCancelSubscription({
              company_id: row.id,
              subscription_status: 5,
            });
            this.loadItems();
            if (response.data.status == "success") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: response.data.message,
                },
              });
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: response.data.message,
                },
              });
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },
    getStatusVariant(status) {
      switch (status) {
        case "complete":
          return "light-success"; // Green for completion
        case "on_payment":
          return "light-success"; // Green for payment in progress
        case "pause":
          return "light-danger"; // Red for paused state
        case "resume":
          return "light-primary"; // Blue for resumed state
        case "cancel":
          return "light-danger"; // Red for cancellation
        case "trial":
          return "light-warning";
        default:
          return "light-secondary"; // default color
      }
    },
    getStatusText(status) {
      switch (status) {
        case "complete":
          return "Complete";
        case "on_payment":
          return "On Payment";
        case "resume":
          return "Resume";
        case "cancel":
          return "Inactive";
        case "pause":
          return "Paused";
        case "trial":
          return "Trial";
        default:
          return "Unknown";
      }
    },

    getPaymentMethodVariant(status) {
      switch (status) {
        case "ssl_commerz":
          return "light-success"; // Green for completion
        case "stripe":
          return "light-primary";
        case "manual":
          return "light-warning";
        default:
          return "light-secondary"; // default color
      }
    },
    getPaymentMethodText(status) {
      switch (status) {
        case "ssl_commerz":
          return "SslCommerz";
        case "stripe":
          return "Stripe";
        case "manual":
          return "Manual";
        default:
          return "Unknown";
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker";
</style>
